<template>
    <div>
        <div class="privacy-main">
        <h1>Privacy Policy Compliance</h1>
<h2>Introduction</h2>
<p>This Privacy Policy outlines how we collect, use, protect, and disclose your information. We are committed to safeguarding your privacy and ensuring that your personal information is protected. This policy complies with relevant privacy laws and regulations. However, for specific legal advice, please consult your legal counsel.</p>
<h2>What Information Do We Collect?</h2>
<p>We collect the following types of information:</p>
<ul>
    <li>
        <p><span>Personal Information</span>: Name, email address, phone number, and other contact details.</p>
    </li>
</ul>
<h2>What Do We Use Your Information For?</h2>
<p>The information we collect may be used for the following purposes:</p>
<ul>
    <li>
        <p>To communicate with you, including sending updates and newsletters.</p>
    </li>
    <li>
        <p>To process transactions and provide customer support.</p>
    </li>
</ul>
<h2>How Do We Protect Your Information?</h2>
<p>We implement a variety of security measures to ensure the safety of your personal information, including:</p>
<ul>
    <li>
        <p>Regular security audits and updates.</p>
    </li>
    <li>
        <p>Restricted access to personal information to authorized personnel only.</p>
    </li>
</ul>
<h2>Do We Disclose Any Information to Outside Parties?</h2>
<p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this Privacy Policy. We may disclose your information to:</p>
<ul>
    <li>
        <p>Trusted third-party service providers who assist us in operating our website and conducting our business, as long as those parties agree to keep this information confidential.</p>
    </li>
    <li>
        <p>Law enforcement agencies, regulators, and other authorities if required by law or to protect our rights, property, or safety.</p>
    </li>
</ul>
<h2>SMS Consent and Phone Numbers</h2>
<p>We collect phone numbers for the purpose of providing SMS consent. Under no circumstances will we share, trade, or sell your phone numbers or SMS consent information to third parties or affiliates for marketing purposes. Your phone number and consent details are strictly used for the intended communication purposes only.</p>
<h2>Changes to Our Privacy Policy</h2>
<p>We reserve the right to update this Privacy Policy at any time. We will notify you of any significant changes by posting the new policy on our website. You are encouraged to review this policy periodically for any updates.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@seattlechoicetransport.com">info@seattlechoicetransport.com</a></p>
</div>
    </div>
</template>
<script>
export default {
  name: 'Policy',}
</script>